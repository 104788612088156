import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AppBreadcrumbComponent } from '../breadcrumb/app.breadcrumb.component';
import { LayoutService } from '../service/app.layout.service';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { SchoolsResponse } from '@modules/configuration/pages/schools/models/Response/schools-response';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { SchoolSelectComponent } from '@shared/components/selects/school-select/school-select.component';
import { SchoolPeriodSelectComponent } from '@shared/components/selects/school-period-select/school-period-select.component';
import { StateService } from '../../shared/service/state.service';
import { AuthService } from '@modules/auth/services/auth.service';
import { AsyncPipe } from '@angular/common';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { UserLoginResponse } from '@modules/auth/pages/login/login.response';
import { StudentsService } from '@modules/sel/pages/students/students.service';
import { StudentsForSearch } from '@modules/sel/pages/students/model/response/students-for-search';
import { TranslocoDirective } from '@jsverse/transloco';
import { Subscription } from 'rxjs';
import { PrimeNgImports } from '@shared/shared.import';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { InstitutionSelectDirective } from '@core/directives/select/institution-select.directive';
import { SelectService } from '@shared/components/selects/select.service';
import { SchoolsForSearch } from '@modules/sel/pages-institution/schools/model/response/schools-for-search';
import { SchoolService } from '@modules/configuration/pages/schools/school.service';
interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [ConfirmationService],
    standalone: true,
    imports: [...PrimeNgImports, AppBreadcrumbComponent, NgClass, InputTextModule, ButtonModule, ConfirmPopupModule, MenuModule,
        SchoolPeriodSelectComponent, SchoolSelectComponent, FormsModule, AutoCompleteModule, AsyncPipe, FormsModule, TranslocoDirective, InstitutionSelectDirective]
})
export class AppTopbarComponent implements OnInit {
    items: MenuItem[] | undefined;
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('schoolSelect') schoolSelect!: SchoolSelectComponent;
    public institutionViewSubscription: Subscription = new Subscription();
    public institutionalView: boolean = false;
    private _stateService = inject(StateService);
    public _authService = inject(AuthService);
    private router = inject(Router);
    private _studentsService = inject(StudentsService);
    private _selectService = inject(SelectService);
    private _schoolService = inject(SchoolService);
    isAdmin = false;
    showAutoComplete = false;
    user: UserLoginResponse = {
        email: '',
        id: 0,
        name: '',
        schoolId: undefined
    };
    schools: SchoolsResponse[] = [];
    selectedSchool: SchoolsResponse | undefined;
    await: any;
    selectedStudent: StudentsForSearch | null = null;
    selectedSchoolForSearch: SchoolsForSearch | null = null;
    institutionsGroups: any;
    institutions: SelectItem<number>[] = [];
    institutionSelected: number = 0;

    constructor(public layoutService: LayoutService, private confirmationService: ConfirmationService) {

        this.getInstitutions();

        this.institutionViewSubscription = this._stateService.InstitutionViewSubject$.subscribe({
            next: (institutionalView) => {
                this.institutionalView = institutionalView;
            }, error: (error) => {
                this.institutionalView = false;
                this._stateService.institutionalView = false;
            }
        })

        this.items = [
            {
                label: 'configuration',
                items: [
                    {
                        visible: true,
                        label: 'users',
                        icon: 'pi pi-user',
                        routerLink: ['configuration/users']
                    },
                    {
                        visible: true,
                        label: 'schools',
                        icon: 'pi pi-graduation-cap',
                        tooltip: 'schools',
                        routerLink: ['configuration/schools']
                    },
                    {
                        visible: true,
                        label: 'schoolPeriods',
                        icon: 'pi pi-calendar',
                        tooltip: 'schoolPeriods',
                        routerLink: ['configuration/school-periods']
                    },
                    {
                        visible: true,
                        label: 'institutions',
                        icon: 'pi pi-home',
                        tooltip: 'institutions',
                        routerLink: ['configuration/institutions']
                    }
                ]
            }
        ];
        this.user = this._authService.getSessionUser();
    }

    getInstitutions() {
        this._selectService.getInstitutionForTopBar().subscribe({
            next: (data) => {
                this.institutions = data;
                if (this.institutions.length > 0) {
                    this._stateService.institutionId = this.institutions[0].value;
                    this.institutionSelected = this.institutions[0].value;
                    this.GetSchoolsForSearch();
                }
            }, error: (error) => {

            }
        })
    }

    ngOnInit(): void {
        this.institutionalView = this._stateService.institutionalView;
    }
    getSchools(idSchoolPeriod: number) {
        this._stateService.schoolPeriodId = idSchoolPeriod;
        this.schoolSelect.GetSchools(idSchoolPeriod);


    }


    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    schoolChange(schoolId: number) {
        const schoolIdBefore = this._stateService.schoolId;
        this._stateService.schoolId = schoolId;
        this._studentsService.GetStudentForSearch().subscribe((data) => {
            this.students = data;
            this.selectedStudent = null;
        });

        if (schoolIdBefore !== schoolId) {
            this.router.navigate(['/']);
        }
    }
    onSearch() {
        this.showAutoComplete = !this.showAutoComplete;
    }


    selectedItem: any;
    students: StudentsForSearch[] = [];
    schoolsForSearch: SchoolsForSearch[] = [];
    filteredStudents: StudentsForSearch[] = [];
    filteredSchools: SchoolsForSearch[] = [];


    filterStudent(event: AutoCompleteCompleteEvent) {
        let filtered: StudentsForSearch[] = [];
        let query = event.query;
        if (query.length == 0) {
            this.filteredStudents = [...this.students];
            return;
        }

        for (let i = 0; i < (this.students).length; i++) {
            let student = (this.students)[i];
            let studentLabel = student.label.toLowerCase();
            query = query.toLowerCase();
            var index = studentLabel.indexOf(query);
            if (studentLabel.indexOf(query) != -1) {
                filtered.push(student);
            }
        }
        this.filteredStudents = filtered;
    }

    filterSchool(event: AutoCompleteCompleteEvent) {
        let filtered: SchoolsForSearch[] = [];
        let query = event.query;
        if (query.length == 0) {
            this.filteredSchools = [...this.schoolsForSearch];
            return;
        }

        for (let i = 0; i < (this.schoolsForSearch).length; i++) {
            let school = (this.schoolsForSearch)[i];
            let schoolLabel = school.label.toLowerCase();
            query = query.toLowerCase();
            var index = schoolLabel.indexOf(query);
            if (schoolLabel.indexOf(query) != -1) {
                filtered.push(school);
            }
        }
        this.filteredSchools = filtered;
    }

    onStudentSelect($event: AutoCompleteSelectEvent) {
        let student = $event.value as StudentsForSearch;
        this.router.navigate(['/app/sel/student/', student.studentId]);
    }

    onSchoolSelect($event: AutoCompleteSelectEvent) {
        let school = $event.value as SchoolsForSearch;
        this._stateService.schoolId = school.schoolId;

        setTimeout(() => {
            this.router.navigate(['/app/institution/sel/schools/', school.schoolId]);
        }, 300);
    }
    institutionChange(event: DropdownChangeEvent) {
        this._stateService.institutionId = event.value;
        this.GetSchoolsForSearch();
        this.router.navigate(['/']);
    }


    private GetSchoolsForSearch() {
        this._schoolService.GetSchoolsForSearch().subscribe((data) => {
            this.schoolsForSearch = data;
            this.selectedSchoolForSearch = null;
        });
    }
}