import { Component, ElementRef, ViewChild, Input, Output, EventEmitter, inject } from '@angular/core';
import { LayoutService } from '../service/app.layout.service';
import { AppMenuComponent } from '../menu/app.menu.component';
import { Router, RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslocoDirective } from '@jsverse/transloco';
import { StateService } from '@shared/service/state.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
  standalone: true,
  styleUrl: './app.sidebar.component.scss',
  imports: [RouterLink, AppMenuComponent, NgClass, OverlayPanelModule, TranslocoDirective],
})
export class AppSidebarComponent {
  openSideBar = true;
  selMenu = true;
  timeout: any = null;
  public logo = '';
  @ViewChild('menuContainer') menuContainer!: ElementRef;
  @ViewChild('overlayPanel', { static: false }) overlayPanel!: OverlayPanel;
  @Output() overlayClicChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() overlayClic: boolean = false;
  private _stateService = inject(StateService);
  private router = inject(Router);

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
  ) {}

  checkWindowSize = () => {
    if (window.innerWidth < 1200) {
      this.openSideBar = true;
      this.layoutService.state.sidebarActive = true;
    } else {
      this.openSideBar = false;
      this.layoutService.state.sidebarActive = false;
    }
  };
  ngOnInit() {
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);
  }

  openMenu() {
    this.openSideBar = !this.openSideBar;
    this.layoutService.state.sidebarActive = !this.layoutService.state.sidebarActive;
  }

  changeEdify() {
    this.selMenu = false;
  }
  changeSEL() {
    this.selMenu = true;
  }

  navigateToAbilities() {
    this._stateService.setSelectedMenu(300);
    this.router.navigate(['/app/abilities']); 
    //     this.router.navigate(['/app/abilities']);
    //     this._stateService.setSelectedMenu(300);
    //     this.layoutService.state.sidebarActive = false;
    //     this.overlayPanel.hide();
  }

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => (this.layoutService.state.sidebarActive = false), 300);
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }
  onContentClick(): void {
    this.overlayClic = true;
    this.overlayClicChange.emit(this.overlayClic);
  }
}
